var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"d-flex"},[_c('h3',{staticClass:"m-0 pr-4 flex-grow-1"},[_vm._v(_vm._s(_vm.$t('message.milestones')))]),_c('el-tooltip',{attrs:{"content":_vm.$t('message.new'),"placement":"top"}},[_c('base-button',{attrs:{"type":"primary","icon":"","round":"","size":"sm","disabled":_vm.project.state === 'ARCHIVED'},on:{"click":_vm.onCreate}},[_c('i',{staticClass:"fas fa-plus p-0"})])],1)],1)]),_c('el-table',{staticClass:"table-responsive table-compact",attrs:{"header-row-class-name":"thead-light","row-key":"id","data":_vm.items}},[_c('el-table-column',{attrs:{"label":_vm.$t('message.name'),"prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)),(row.projectAddress)?[_vm._v(" ("+_vm._s(row.projectAddress.address.name)+")")]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('message.dueDate'),"prop":"due","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("date")(row.due))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('message.done'),"prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.completed)?[_c('i',{staticClass:"fas fa-check"})]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"","width":"88"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"content":_vm.$t('message.edit'),"placement":"top"}},[_c('base-button',{attrs:{"type":"link","size":"sm","disabled":_vm.project.state === 'ARCHIVED'},on:{"click":function($event){return _vm.onEdit(row)}}},[_c('i',{staticClass:"fas fa-edit text-black"})])],1),_c('el-tooltip',{attrs:{"content":_vm.$t('message.delete'),"placement":"top"}},[_c('base-button',{attrs:{"type":"link","size":"sm","disabled":_vm.project.state === 'ARCHIVED'},on:{"click":function($event){return _vm.onDelete(row)}}},[_c('i',{staticClass:"fas fa-trash text-black"})])],1)]}}])})],1),(_vm.item)?_c('milestone-form',{attrs:{"show":_vm.showForm,"milestone":_vm.item},on:{"update:show":function($event){_vm.showForm=$event},"changed":_vm.onChanged}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }