<template>
  <modal @update:show="onShowChange" :show="show">
    <h6
      slot="header"
      class="modal-title"
    >{{ milestone.id ? $t('message.edit_milestone') : $t('message.new_milestone') }}</h6>
      <base-input ref="name" label="Name" placeholder="Name" v-model="milestone.name" :validator="$v.milestone.name"></base-input>
      <base-input :label="$t('message.einsatzort')" :validator="$v.milestone.p_address_id">
        <el-select v-model="milestone.p_address_id" filterable :placeholder="$t('message.locationSelect')">
          <el-option
            v-for="address in projectAddresses"
            :key="address.id"
            :label="address.address.name + ', ' + address.address.zip + ' ' + address.address.city"
            :value="address.id"
          ></el-option>
        </el-select>
      </base-input>
      <base-input :label="$t('message.dueDate')">
        <flat-picker
                slot-scope="{focus, blur}"
                v-model="milestone.due"
                :placeholder="$t('message.ChooseDatePicker')"
                @on-open="focus"
                @on-close="blur"
                :config="dateTimePicker"
                class="form-control datepicker"
        />
      </base-input>
      <base-checkbox class="mt--3 mb-3" v-model="milestone.completed">{{$t('message.done')}}</base-checkbox>
      <base-input :label="$t('message.visibility')">
        <base-checkbox v-model="milestone.internal">{{$t('message.internal')}}</base-checkbox>
      </base-input>

    <template slot="footer">
      <base-button type="link" class="ml-auto" @click="onClose">{{$t('message.cancel')}}</base-button>
      <base-button type="primary" @click="onSave" :disabled="$v.$invalid">{{$t('message.save')}}</base-button>
    </template>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import { required, minLength, between } from "vuelidate/lib/validators";

export default {
  name: "externals-form",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    milestone: {
      type: Object
    }
  },

  data() {
    return {
      isLoading: false,
      dateTimePicker: {
        enableTime: true,
        time_24hr: true,
        dateFormat: "Y-m-d H:i:00",
        altInput: true,
        altFormat: "d.m.Y H:i"
      },
    };
  },
  validations: {
    milestone: {
      name: { required },
      due: { required }
    },
  },
  computed: {
    ...mapGetters(["projectAddresses"])
  },
  watch: {
    show: {
      immediate: true,
      handler(value) {
        if (value)
          this.focusInput();
      }
    }
  },
  methods: {
    focusInput() {
      this.$nextTick(() => {
        this.$refs.name.$el.querySelector('input').focus();
      })
    },
    onClose() {
      this.onShowChange(false);
    },
    onSave: async function() {
      const { $store, milestone, project } = this;
      // const { p_address_id, name, due, ...data } = milestone;

      this.isLoading = true;
      // delete milestone.tasks
      if (milestone.id) {
        delete milestone.projectAddress
        if (milestone.p_address_id === null) {
          delete milestone.p_address_id
        }

        console.log('save milestone', milestone)

        await $store.dispatch("updateMilestone", {
          id: milestone.id,
          data: milestone
        })
      } else {
        await $store.dispatch('createMilestone', { data: milestone });
      }

      this.isLoading = false;
      this.$emit("changed");
      this.onClose();
    },
    onShowChange(state) {
      this.$emit("update:show", state);
    }
  },
  mounted() {
    this.focusInput();
    this.$store.dispatch("findUsers");
  }
};
</script>
