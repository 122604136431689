<template>
  <div>
    <base-header>
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <!-- <h6 class="h2 d-inline-block mb-0">{{$route.name}}</h6> -->
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb />
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral" @click="report">{{ $t('message.export') }}</base-button>
          <base-button size="sm" type="neutral" @click="reportWork">{{ $t('message.work') }}</base-button>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <project-details @save="onProjectSave" @archive="onProjectArchive" @template="onProjectTemplate" :project="project"></project-details>
      <div class="row"  v-if="project && project.id">
        <div class="col-xl-6">
          <div class="card-wrapper">
            <einsatzorte :project="project" />
            <rides :project="project" />
            <insurances :project="project" />
            <tools :project="project" />
          </div>
        </div>
        <div class="col-xl-6">
          <div class="card-wrapper">
            <team caption="Teams" :project="project"></team>
            <externals
              :project="project"
              :allow-add-user="true"
              :employees="project.responsiblePersons"
              :external-roles="[{name: 'Druckdaten'}, {name: 'Angebotslisten'}, {name: 'Vor Ort'}]"
            ></externals>
            <options :project="project" type="AREA" :label="$t('message.area')"></options>
            <options :project="project" type="BHBPOS" :label="$t('message.positionBHB')"></options>
            <milestones :project="project"></milestones>
          </div>
        </div>

      </div>
      <hotels :project="project"  v-if="project && project.id"></hotels>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import FileInput from "@/components/Inputs/FileInput";
import store from "@/store";
import download from '@/util/download'

import Einsatzorte from "./Components/Einsatzort/Einsatzorte";
import Externals from "./Components/Externals/Externals";
import Hotels from "./Components/Hotels/Hotels";
import Options from "./Components/Option/Option";
import ProjectDetails from "./Components/ProjectDetails";
import Rides from "./Components/Rides/Rides";
import Insurances from "./Components/Insurances/Insurances";
import Team from "./Components/Team/Team";
import Tools from "./Components/Tools/Tools";
import Milestones from "./Components/Milestones/Index";

export default {
  name: "form-elements",
  components: {
    FileInput,
    Externals,
    Einsatzorte,
    Hotels,
    Options,
    ProjectDetails,
    Rides,
    Insurances,
    Team,
    Tools,
    Milestones
  },
  computed: {
    isNew() {
      if (this.project && this.project.id) {
        return false;
      } else {
        return true;
      }
    },
    ...mapGetters([
      "projects",
      "project_orders",
      "currentUser",
      "standardColors"
    ])
  },
  data() {
    return {
      project: {
        functions: ''
      }
    };
  },
  methods: {
    ...mapActions([
      "loadProjectOrders",
      "getFullProject",
      "loadProjectOptions"
    ]),
    onProjectSave(newProject, allAdditionalCustomers) {
      const self = this;
      delete newProject.customer;
      delete newProject.projectPersons;
      delete newProject.documents;
      let p;
      if (this.isNew) {
        p = this.$store
          .dispatch("createProject", { data: newProject })
          .then(res => {
            this.addStandardColors(res.id);
            this.updateProjectOrders(res.id, newProject, allAdditionalCustomers);
            this.refreshProject(newProject, res.id);
            this.$router.push({ path: `/project/${res.id}/edit` });
          });
      } else {
        delete newProject.notifications;
        p = this.$store
          .dispatch("updateProject", { id: newProject.id, data: newProject })
          .then(() => {
            this.addStandardColors(newProject.id);
            this.updateProjectOrders(newProject.id, newProject, allAdditionalCustomers);
            this.$store.dispatch("getFullProject", this.$route.params.id );
          });
      }
      p.then(() => {
        this.$notify({
          verticalAlign: "top",
          horizontalAlign: "right",
          message: this.$t('message.notificationProjectIsSaved'),
          type: "success"
        });
      });
      p.catch(error => {
        this.$notify({
          verticalAlign: "top",
          horizontalAlign: "right",
          message: this.$t('message.notificationProjectCannotSaved'),
          type: "error"
        });
      });
    },
    async onProjectArchive(project) {
      const newState = project.state=='ARCHIVED' ? 'NEW' : 'ARCHIVED'
      // if (newState === 'ARCHIVED') {
      //   // todo: check open tasks
      //   const openTasks = await this.$store.dispatch('findTasks', {
      //     data: {
      //       and: {
      //         project_id: project.id,
      //         state: 'NEW'
      //       }
      //     },
      //     options: {
      //       commit: false,
      //     },
      //   });
        
      //   if (openTasks.length > 0) {
      //      try {
      //         await this.$confirm(
      //           this.$t(`message.archive_warning`, { openTasks: openTasks.length }),
      //           this.$t("message.warning"),
      //           {
      //             confirmButtonText: this.$t("message.yes"),
      //             cancelButtonText: this.$t("message.no"),
      //             type: "warning"
      //           }
      //         );
      //       } catch (error) {
      //         return
      //       }
      //   }
      // }

      await this.$store
        .dispatch("updateProject", {
          id: project.id,
          data: { state: newState }
        })
        .then(() => this.$store.dispatch("loadAllProjects"))
        .then(() => {
          this.$notify({
            verticalAlign: "top",
            horizontalAlign: "right",
            message: this.$t('message.notificationProjectIsArchived'),
            type: "success"
          });
          this.$router.push({ path: "/projects" });
              });
    },
    onProjectTemplate(project, tmpl) {
      this.$store
              .dispatch("updateProject", {
                id: project.id,
                data: { template: tmpl }
              })
              .then(() => {
                project.template = tmpl;
                this.$store.dispatch("loadAllProjects");
                this.$notify({
                  verticalAlign: "top",
                  horizontalAlign: "right",
                  message: tmpl ? this.$t('message.notificationProjectIsTemplate') : this.$t('message.notificationProjectIsNoTemplate'),
                  type: "success"
                });
              });
    },
    async addStandardColors(projectId) {
      const savedColors = await this.loadProjectOptions(projectId)
                              .then(s => s.filter(o => o.type == 'COLOR'))
                              .then(c => c.map(oc => oc.name))

      const newColors = this.standardColors.filter(c => !savedColors.includes(c))

      newColors.map( c => {
        this.$store.dispatch("createOption", 
          { data: {
              project_id: projectId,
              type: 'COLOR',
              name: c
            }
          })
      })
    },
    updateProjectOrders(projectId, newProject, allAdditionalCustomers) {
      let orderNos = newProject.orders;
      let newOrderNos = [], updateNos = [], newAddOrderNos = [];
      if (orderNos != null) {
        newOrderNos = orderNos.map(no => ({
          project_id: projectId,
          order_no: no,
          description: newProject.orderDescription
        }));
      }
      if (orderNos == null && newProject.orderDescription != null) {
        const oldIds = this.project_orders.filter(o => o.additional_customer_no == null).map(po => po.id);
        oldIds.forEach(item => {
          updateNos.push({
            id: item,
            data: {description: newProject.orderDescription}
          })
        })
      }
      if (allAdditionalCustomers != null) {
        newAddOrderNos = allAdditionalCustomers.map(order => order.orders.map( o => ({
            project_id: projectId,
            order_no: o,
            additional_customer_no: order.customer_no,
            description: order.description,
            ongoing_no: order.ongoing
          }))
        ).flat()
      }
      var done = null;

      if (newOrderNos.length > 0) {
        const savedOrders = this.project_orders.filter(o => o.project_id == projectId && o.additional_customer_no == null);
        let newOrders = newOrderNos.map(o => o.order_no);
        let savedOrderNos = savedOrders.map(o => o.order_no);
        let deleteOldIds = this.reduceOrders(savedOrders, newOrders);
        let ordersToSave = this.reduceOrders(newOrderNos, savedOrderNos, true);

        if (savedOrders.length > 0 && deleteOldIds.length > 0) {
          done = this.$store.dispatch("deleteProject_order", { id: deleteOldIds });
        } else {
          done = this.$store.dispatch("createProject_order", { data: ordersToSave });
        }
      }
      if (updateNos.length > 0) {
        updateNos.forEach(update => {
          done = this.$store.dispatch("updateProject_order", update)
        })
      }
      
      const savedAdditionals = this.project_orders.filter(o => o.additional_customer_no != null);
      let newAddOrders = newAddOrderNos.map(o => o.order_no);
      let savedAddOrders = savedAdditionals.map(o => o.order_no);
      let deleteAddOrderIds = this.reduceOrders(savedAdditionals, newAddOrders);
      let addOrdersToSave = this.reduceOrders(newAddOrderNos, savedAddOrders, true);
      let updateAddOrders = savedAdditionals.filter(item => {
        return newAddOrderNos.filter(i => {
          if(i.order_no == item.order_no && i.additional_customer_no == item.additional_customer_no) {
            item.description = i.description
          }
        })
      })

      if(updateAddOrders.length > 0 ) {
        updateAddOrders.forEach(update => {
          done = this.$store.dispatch("updateProject_order", {id: update.id, data: update})
        })
      }

      if (deleteAddOrderIds.length > 0 && addOrdersToSave.length > 0) {
        done = this.$store
                    .dispatch("deleteProject_order", { id: deleteAddOrderIds })
                    .then(() =>
                      this.$store.dispatch("createProject_order", { data: addOrdersToSave })
                    );
      } else if (deleteAddOrderIds.length > 0 && addOrdersToSave.length == 0) {
        done = this.$store.dispatch("deleteProject_order", { id: deleteAddOrderIds })
      } else if (deleteAddOrderIds.length == 0 && addOrdersToSave.length > 0) {
        done = this.$store.dispatch("createProject_order", { data: addOrdersToSave })
      }
      
      if (done)
        done.then(() => this.loadProjectOrders(projectId));
    },
    reduceOrders(savedOrders, newOrders, save) {
      let reduced = []
      savedOrders.forEach((item) => {
        if (!newOrders.includes(item.order_no)) {
          reduced.push( save === true ? item : item.id )
        }
      })
      return reduced;
    },
    refreshProject(newProject, id) {
      this.project.id = id;
      this.$set(this.project, id);
    },
    async report() {
      var offset = new Date().getTimezoneOffset();
      const url = 'pdf/project/'+this.project.id+'/facts'
            + "?tz=" + (-offset/60)
      download(url, this.project.name + "-Factsheet.pdf")
    },
    async reportWork() {
      var offset = new Date().getTimezoneOffset();
      const url = 'pdf/project/'+this.project.id+'/work'
            + "?tz=" + (-offset/60)
      download(url, this.project.name + "-Arbeit.pdf")
    },
  },
  beforeRouteLeave(to, from, next) {
    // store.commit('project', null)
    next();
    // const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
    // if (answer) {
    //   next()
    // } else {
    //   next(false)
    // }
  },
  mounted() {
    this.$store.dispatch('findVehicles')

    if (this.$route.params.id) {
      this.$store
        .dispatch("getFullProject", this.$route.params.id)
        .then(result => {
          this.loadProjectOrders(this.$route.params.id);
          this.project = Object.assign({}, result);

        });
    } else {
      this.project = {
        name: "",
        key: "-",
        functions: ['D', 'B', 'A', 'M', 'E', 'K', 'L', 'U', 'I', 'T', 'R'].join(''),
        description: "",
        path: "/",
        template: false,
        state: "NEW",
        pt_number: "",
        orders: []
      };
    }
  }
};
</script>
